@import '../../assets/styles/global';

.Admin {
  &.visible {
    .Admin {
      &-body {
        padding-left: 23.6rem;
      }
    }
  }

  &-navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
  }

  &-body {
    min-width: 1280px;
    transition: $common-transition;
    padding-left: 7.2rem;
  }
}