@import '../../assets/styles/global';

.Icon {

  img,
  svg {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
    object-position: center;
  }
}