@import '../../assets/styles/global';

.Auth {
  min-height: 100vh;
  padding: 8rem;

  &>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}