@font-face {
  font-family: Pretendard;
  font-weight: 900;
  src: url('../fonts/Pretendard/Pretendard-Black.otf') format('opentype');
}

@font-face {
  font-family: Pretendard;
  font-weight: 800;
  src: url('../fonts/Pretendard/Pretendard-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: Pretendard;
  font-weight: 700;
  src: url('../fonts/Pretendard/Pretendard-Bold.otf') format('opentype');
}

@font-face {
  font-family: Pretendard;
  font-weight: 600;
  src: url('../fonts/Pretendard/Pretendard-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: Pretendard;
  font-weight: 500;
  src: url('../fonts/Pretendard/Pretendard-Medium.otf') format('opentype');
}

@font-face {
  font-family: Pretendard;
  font-weight: 400;
  src: url('../fonts/Pretendard/Pretendard-Regular.otf') format('opentype');
}

@font-face {
  font-family: Pretendard;
  font-weight: 300;
  src: url('../fonts/Pretendard/Pretendard-Light.otf') format('opentype');
}

@font-face {
  font-family: Pretendard;
  font-weight: 200;
  src: url('../fonts/Pretendard/Pretendard-ExtraLight.otf') format('opentype');
}

@font-face {
  font-family: Pretendard;
  font-weight: 100;
  src: url('../fonts/Pretendard/Pretendard-Thin.otf') format('opentype');
}

