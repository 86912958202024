/*
- Please follow this tool to name your color, then please define the color in src/common-types/ui.ts also
  https://chir.ag/projects/name-that-color/#6195ED

- If colors same name, please add suffix. Ex: persian-blue, persian-blue-dark, persian-blue-light, ...
*/

$white: #ffffff;
$black: #000000;
$background: #f9f9f9;
$turbo: #FEE500;
$jon: #3C1E1E;
$masala: #474544;
$wild-sand: #F5F5F5;
$kakao-blue: #AFC1CF;
$kakao-yellow: #F6E24C;

$gray-09: #1A1A1A;
$gray-08: #333333;
$gray-07: #4C4C4C;
$gray-06: #666666;
$gray-05: #808080;
$gray-04: #999999;
$gray-03: #B3B3B3;
$gray-02: #CCCCCC;
$gray-01: #E5E5E5;

$red-07: #D61438;
$red-06: #ED3658;
$red-05: #FF5F7C;
$red-04: #FF879D;
$red-03: #FFB5C2;
$red-02: #FFD8DF;
$red-01: #FFF2F5;

$blue-07: #708FFF;
$blue-06: #99A3FF;
$blue-05: #BEC5FF;
$blue-04: #DADEFF;
$blue-03: #E9EBFF;
$blue-02: #F3F4FF;

$violet-07: #8A46FA;
$violet-06: #99A3FF;
$violet-05: #BEC5FF;
$violet-04: #DADEFF;
$violet-03: #E9EBFF;
$violet-02: #EDE2FF;
$violet-01: #F6F0FF;

$yellow-07: #FDC025;
$yellow-06: #FFD569;
$yellow-05: #FFE193;
$yellow-04: #FFEBB8;
$yellow-03: #FFF3D4;
$yellow-02: #FFF8E8;
$yellow-01: #FFFBF3;

$error: #FF4F4F;
$success: #1BD693;
$warning: #FFD600;