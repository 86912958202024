@import '~antd/dist/antd.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

@import './normalize';
@import './fonts';
@import './colors';
@import './constants';
@import './mixins';
@import './typography';

html {
  font-size: 10px;

  @media (max-width: 1320px) {
    font-size: 9px;
  }
}

* {
  font-family: Pretendard, sans-serif;
  scroll-behavior: smooth;
}

body {
  background: $wild-sand;
}

.container {
  padding: 0 1.6rem;
  max-width: calc(120rem + 1.6rem * 2);
  width: 100%;
  margin: 0 auto;
}

.container-page {
  max-width: 1440px;
  padding: 2.4rem 2rem;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.nowrap {
  white-space: nowrap;
}

.bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

a {
  &:hover {
    color: inherit;
  }
}

.ant-form {
  .ant-form-item {
    margin-bottom: 3.2rem;

    &.horizontal {
      display: flex;
      flex-direction: row;

      &.items-center {
        .ant-form-item-label {
          margin-top: 0;
        }
      }

      .ant-form-item-label {
        margin-top: 1.4rem;
        padding-bottom: 0;
        margin-right: 1.6rem;
      }
    }
  }

  .ant-form-item-explain {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: $gray-09;
    margin-left: 0.4rem;
  }

  .ant-form-item-label {
    padding-bottom: 0.8rem;

    &>label {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number-disabled,
.ant-input-number-disabled+.ant-input-number-group-addon,
.DatePicker .ant-picker.ant-picker.ant-picker-disabled,
.ant-input[disabled] {
  background: $background;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.relative {
  position: relative;
}

.uppercase {
  text-transform: uppercase;
}

.ant-notification {
  pointer-events: none;
}

.Notification {
  width: fit-content;
  padding: 1.6rem 4.8rem;
  border-radius: .8rem;
  box-shadow: 0px .8rem 3.2rem rgba(214, 20, 56, 0.12);
  background: $red-05;

  .ant-notification-notice-description {
    color: $white
  }

  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-notification-notice-icon {
      position: unset;
      margin-left: 0;
      font-size: unset;
      line-height: unset;
    }

    .ant-notification-notice-message {
      display: none;
    }
  }

  .ant-notification-notice-description {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    margin-left: .4rem;
  }

  .Icon {
    width: 2.4rem;
    height: 2.4rem;
  }
}