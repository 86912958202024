@import '@/assets/styles/_global.scss';

.Navbar {
  width: 23.6rem;
  transition: $common-transition;
  background: $gray-08;
  height: 100vh;
  overflow: auto;
  transition: $common-transition;

  &.hide {
    width: 7.2rem;

    .Navbar {
      &-header {
        padding: 3.2rem 1.6rem;

        &-info {
          display: none;
        }
      }

      &-list {
        &-item {
          padding: 1.8rem 2.4rem;

          &-title {
            display: none;
          }
        }
      }

      &-footer {
        padding: 2.4rem 2rem;

        .Icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-header {
    padding: 3.2rem 2.4rem;
    transition: $common-transition;

    &-icon {
      flex: 0 0 4rem;
      max-width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background: $gray-02;
      display: flex;
      margin-right: 1.2rem;

      .Icon {
        margin: auto;
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    &-info {

      &-name,
      &-branch {
        white-space: nowrap;
        color: $white;
      }
    }
  }

  &-list {
    &-item {
      cursor: pointer;
      transition: $common-transition;
      padding: 1.8rem 3.2rem;

      &.child {
        padding: 1.2rem 3.2rem;
      }

      &-children {
        overflow: hidden;
        max-height: 0;
        transition: $common-transition;

        &.active {
          max-height: 50vh;
        }
      }

      &-wrapper {
        &:not(:last-child) {
          margin-bottom: .8rem;
        }
      }


      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }

      &:not(.disabled):hover,
      &.active {
        .Navbar-list-item {
          &-title {
            color: $white;
          }
        }
      }

      &:not(.disabled):not(.child):hover,
      &:not(.child).active {
        background: $red-06;

        .Navbar-list-item {

          &-arrow,
          &-icon {
            .Icon {
              .fill {
                fill: $white;
              }

              .stroke {
                stroke: $white;
              }
            }
          }

          &-title {
            color: $white;
          }
        }
      }

      &-icon {
        flex: 0 0 2.4rem;
        max-width: 2.4rem;
        height: 2.4rem;
      }

      &-arrow {
        flex: 0 0 2rem;
        max-width: 2rem;
        height: 2rem;
        transition: $common-transition;

        &.active {
          transform: rotate(180deg);
        }
      }

      .Icon {
        width: 100%;
        height: 100%;

        .fill,
        .stroke {
          transition: $common-transition;
        }
      }

      &-title {
        white-space: nowrap;
        margin: 0 .8rem;
        color: $gray-03;
        transition: $common-transition;
      }
    }
  }

  &-footer {
    margin-top: auto;
    padding: 2.4rem;

    .Icon {
      cursor: pointer;
      width: 3.2rem;
      height: 3.2rem;
      transition: $common-transition;

      &:hover {
        .fill {
          fill: $red-06;
        }

        .stroke {
          stroke: $red-06;
        }
      }

      .fill,
      .stroke {
        transition: $common-transition;
      }
    }
  }
}