.header-3 {
  font-size: 6.4rem;
  line-height: 7.2rem;
  font-weight: 700;
}

.header-2 {
  font-size: 6rem;
  line-height: 6.8rem;
  font-weight: 700;
}

.header-1 {
  font-size: 5.2rem;
  line-height: 6.4rem;
  font-weight: 700;
}

.display-5 {
  font-size: 4.8rem;
  line-height: 5.2rem;
  font-weight: 700;
}

.display-4 {
  font-size: 4.4rem;
  line-height: 4.8rem;
  font-weight: 700;
}

.display-3 {
  font-size: 4rem;
  line-height: 4.8rem;
  font-weight: 700;
}

.display-2 {
  font-size: 3.6rem;
  line-height: 4.4rem;
  font-weight: 700;
}

.display-1 {
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 700;
}

.headline-3 {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 700;
}

.headline-2 {
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 700;
}

.headline-1 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 700;
}

.title-3 {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
}

.title-2 {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 700;
}

.title-1 {
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 700;
}

.sub-title {
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
}

.body-5 {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 400;
}

.body-4 {
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 400;
}

.body-3 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 400;
}

.body-3-long {
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 400;
}

.body-2 {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
}

.body-2-long {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
}

.body-1 {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 400;
}

.body-1-long {
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 400;
}

.caption-2 {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
}

.caption-1 {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
}

